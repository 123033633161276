<template>
    <div class="resumeReport">
        <div class="search" style="margin-bottom: 20px">
            <div class="tag-group">
                
                <div class="tag-item" v-if="searchList.length>0" v-for="(item,index) in searchList">
                    <a-tag class="tags">{{item.name}}:</a-tag>
                    <a-tag
                            v-if="item.type=='person'"
                            :closable="closable"
                            v-for="(person,index2) in item.value"
                            :key="person.id"
                            @close="() => personClose(index,index2)"
                            class="tags-active tags">
                        <span>{{person.title}}</span>
                    </a-tag>
                    <!--:key="item.value"-->
                    <a-tag
                            v-if="item.type !='person'"
                            :closable="closable"
                            @close="() => searchClose(index)"
                            class="tags-active tags">
                        <span v-if="item.field=='PositionLabel'">{{PositionObject[item.value]}}</span>
                        <span v-if="item.field=='CreateTime'">{{StartTime}} --- {{EndTime}}</span>
                        <span v-else>{{item.value}}</span>
                    </a-tag>                                            
                </div>
            </div>
        </div>

        <div class="table" :style="'width:' +($store.state.app.showNav ? pageWidth - 220 : pageWidth) +'px;'">
            <a-table
                    :data-source="tableList"
                    :pagination="false"
                    :columns="tableColumns"
                    :scroll="{ x: 1500 }">
                <div slot="PositionName">
                     <span>职位名称<i class="iconfont icon-shaixuan filter-icon"
                                  @click="_showMOdelTest('select',$event,'职位名称','PositionLabel')"></i></span>
                </div>
                <div slot="InchargeUserName">
                       <span>招聘负责人<i class="iconfont icon-shaixuan filter-icon"
                                    @click="_showMOdelTest('person',$event,'招聘负责人','InchargeUserId')"></i></span>
                </div>
                <div slot="Status">
                    <span>招聘状态</span>
                </div>
                <template slot-scope="text, record" slot="Status">
                      {{StatusObject[record.Status]}}
                </template>
                <div slot="CreateTime">
             <span>创建时间 <i class="iconfont icon-shaixuan filter-icon"
                          @click="_showMOdelTest('date',$event,'创建时间','CreateTime')"></i></span>
                </div>
            </a-table>
        </div>
        <a-pagination
                v-if="total"
                style="margin-top: 35px;text-align: right;"
                v-model="Page"
                @change="ChangePage"
                :pageSize="Limit"
                :total="total"

        />
        <tableHeader :showMOdelTest="showMOdelTest" @getData="_tableGetData"></tableHeader>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import tableHeader from '../../components/tableHeader/filterBox';
    import person from "../../components/SelectPersonnel/SelectPersonnel"; //选人
    export default {
        components: {
            tableHeader, person
        },
        name: "resumeReport",
        data() {
            return {
                Limit: 10,
                Page: '',
                total: '',
                tableList: [],
                tableColumns: [
                    {

                        dataIndex: 'PositionName',
                        width: '120px',
                        align: 'center',
                        slots: {title: 'PositionName'},
                        fixed:'left',
                    },
                    {

                        dataIndex: 'Status',
                        width: '120px',
                        align: 'center',
                        slots: {title: 'Status'},
                        scopedSlots: {customRender: 'Status'},
                    },
                    {
                        title:'Hr开始时间',
                        dataIndex: 'StartRecruitDate',
                        width: '120px',
                        align: 'center',

                    },
                    {

                        dataIndex: 'InchargeUserName',
                        width: '120px',
                        align: 'center',
                        slots: {title: 'InchargeUserName'},
                    },
                    {
                        title:'Hr结束时间',
                        dataIndex: 'EndRecruitDate',
                        width: '120px',
                        align: 'center',

                    },
                    {
                        title:'累计用时',
                        dataIndex: 'TotalDay',
                        width: '120px',
                        align: 'center',

                    },

                    {
                        title:'期望完成时间',
                        dataIndex: 'ExpectedCompletionDate',
                        width: '120px',
                        align: 'center',

                    },
                    {
                        title:'简历总数',
                        dataIndex: 'ResumeNum',
                        width: '120px',
                        align: 'center',
                    },
                    {
                        title:'用人部门通过',
                        dataIndex: 'EmployingDepartmentPass',
                        width: '120px',
                        align: 'center',
                    },
                    {
                        title:'用人部门不通过',
                        dataIndex: 'EmployingDepartmentNotPass',
                        width: '160px',
                        align: 'center',
                    },
                    {
                        title:'初试通过',
                        dataIndex: 'FirstTryPass',
                        width: '120px',
                        align: 'center',
                    },
                    {
                        title:'初试不通过',
                        dataIndex: 'FirstTryNotPass',
                        width: '120px',
                        align: 'center',
                    },
                    {
                        title:'复试通过',
                        dataIndex: 'RetestPass',
                        width: '120px',
                        align: 'center',
                    },
                    {
                        title:'复试不通过',
                        dataIndex: 'RetestNotPass',
                        width: '120px',
                        align: 'center',
                    },
                    {
                        title:'终试通过',
                        dataIndex: 'FinalTestPass',
                        width: '120px',
                        align: 'center',
                    },
                    {
                        title:'终试不通过',
                        dataIndex: 'FinalTestNotPass',
                        width: '120px',
                        align: 'center',
                    },
                    {
                        title:'发送offer',
                        dataIndex: 'SendOffer',
                        width: '120px',
                        align: 'center',
                    },
                    {
                        title:'接受offer',
                        dataIndex: 'AcceptOffer',
                        width: '120px',
                        align: 'center',
                    },
                    {

                        title:'入职',
                        dataIndex: 'Entry',
                        width: '120px',
                        align: 'center',
                    },
                    {
                        dataIndex: 'CreateTime',
                        width: '120px',
                        align: 'center',
                        fixed:'right',
                        slots: {title: 'CreateTime'},
                    },

                ],
                StatusObject:{"0":"未开始","1":"已完成","2":"招聘中"},
                pageWidth: '',
                showMOdelTest: {
                    show: false,
                    type: 'content',
                    init: ''
                },
                searchList:[],
                selectType:'',
                StartTime:'',
                EndTime:'',
                closable:true,
                PositionArr:[],
                PositionObject:[],
            }
        },
        computed: {
            ...mapState({
                showNav: state => state.app.showNav
            }),

        },
        created() {
            this.pageWidth = document.documentElement.clientWidth - 220;
            this.loadData();
            this.positionList();
        },
        methods: {
            positionList() {
                let self = this
                this.$axios.get(1252, {}, res => {
                    if (res.data.code == 0) {
                        // self.personData = res.data.data
                        for (let j = 0; j < res.data.data.length; j++) {
                            let arr = {}
                            arr.name = res.data.data[j].PositionName
                            arr.value = res.data.data[j].PositionLabel
                            self.PositionObject[res.data.data[j].PositionLabel] = res.data.data[j].PositionName;
                            self.PositionArr.push(arr)
                        }

                    }
                })
            },
            loadData() {
                let self = this;
                let data = {
                    pagesize: self.Limit,
                    p: self.Page,
                    EndTime:self.EndTime,
                    StartTime:self.StartTime,
                };

                if (this.searchList.length != 0) {
                    console.log(this.searchList) ;
                    for (let i = 0; i < self.searchList.length; i++) {
                        if(self.searchList[i].field !='CreateTime'){
                            if(self.searchList[i].type == 'person'){
                                let person = [];
                                if (self.searchList[i].value) {
                                    for (let s = 0; s < self.searchList[i].value.length; s++) {
                                        person.push(self.searchList[i].value[s].id);
                                    }
                                }
                                data[self.searchList[i].field] = person.toString();
                            }else{
                                data[self.searchList[i].field] = self.searchList[i].value;
                            }

                        }
                    }
                }
                this.$axios.get(12924, data, res => {
                    if (res.data.code == 0) {
                        self.tableList = res.data.data;
                        self.total = res.data.total;
                    } else {
                        self.tableList = [];
                        self.total = '';
                    }
                })
            },
            ChangePage(val) {
                this.Page = val;
                this.loadData();
            },
            addDate(){
                const nowDate = new Date();
                const date = {
                    year: nowDate.getFullYear(),
                    month: nowDate.getMonth() + 1,
                    date: nowDate.getDate(),
                }
                const newmonth = date.month>10?date.month:'0'+date.month
                const day = date.date>10?date.date:'0'+date.date
                let updateTime = date.year + '-' + newmonth + '-' + day
                return updateTime;
            },
            _tableGetData(val) {
                let self = this;
                for (let i = 0; i < self.searchList.length; i++) {
                    if (self.searchList[i].field == self.selectType) {
                        if(self.searchList[i].field =='CreateTime'){
                            if(val.length ==2){
                                self.StartTime = val[0];
                                self.EndTime = val[1];
                            }else{
                                self.StartTime = val;
                                self.EndTime = self.addDate();
                            }
                        }
                       self.searchList[i].value = val;
                    }
                }
                console.log(this.searchList);
                this.Page = 0;
                this.loadData();
            },
            _showMOdelTest(type, e, list, txt) {
                this.selectType = txt;
                let init = '';
                let arr = {};
                arr.name = list;
                arr.field = txt;
                arr.value = '';
                arr.type = type;
                if (this.searchList.length == 0) {
                    this.searchList.push(arr);
                } else {
                    let self = this;
                    let activity = 0;
                    for (let i = 0; i < self.searchList.length; i++) {
                        if (self.searchList[i].field == txt) {
                            activity = 1;
                        }
                    }
                    if (activity != 1) {
                        this.searchList.push(arr);
                    }
                }
                let data = {
                    list: list,
                    type: type,
                    show: true,
                    init: init,
                    event: e
                };
                if(txt=='PositionLabel'){
                    data.list = this.PositionArr;
                }

                this.showMOdelTest = data;
            },
            searchClose(i) {
                if (this.searchList.length == 1) {
                    this.searchList = []
                } else {
                    this.searchList.splice(i, 1)
                }
                this.Page = 0;
                this.loadData();
            },
            personClose(key, i) {
                let valueArr = this.searchList[key].value;
                if (valueArr.length == 1) {
                    this.searchList.splice(key, 1)
                } else {
                    valueArr.splice(i, 1);
                    this.searchList[key].value = valueArr;
                }
                this.Page = 0;
                this.loadData();
            },
        }
    }
</script>

<style scoped lang="less">
    .resumeReport {
        background: #ffffff;
        padding: 20px;
        min-height: 800px;
    }
</style>